'use strict';

Gri.module({
  name: 'list-beta',
  ieVersion: null,
  $el: $('.list-beta'),
  container: '.list-beta',
  fn: function () {
    
  }
});
